﻿// accordion and slider scripts - see global assets
require('../../ga/scripts/partials/slickslider');

module.exports = {
  init: () => {
    $('.container:not(.redesign-spo-carousel) .spo-carousel').slick({
      accessibility: false,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      adaptiveHeight: true,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 544,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    });
    $('.redesign-spo-carousel .spo-carousel').slick({
      accessibility: false,
      dots: false,
      infinite: true,
      swipeToSlide: true,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 2.25,
            swipeToSlide: true,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 544,
          settings: {
            infinite: false,
            slidesToShow: 2.25,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false
          }
        }
      ]
    });
    document.querySelector('.spo-carousel').classList.remove('invisible');
  }
};

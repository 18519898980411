﻿/*
  mall-homepage.js
  Mall Level Homepage
*/

// slider scripts
require('../../ga/scripts/partials/slickslider');
const spoCarousel = require('./spo-carousel');

(function () {
  // event listeners
  document.addEventListener('DOMContentLoaded', function () {
    if(document.querySelector('.js-down-arrow')) {
      document.querySelector('.js-down-arrow').addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        const el = document.scrollingElement ? document.scrollingElement : document.documentElement;
        $(el).animate({ scrollTop: window.innerHeight - 74 }, 400);
      });
    }

    $('.hero-slider').slick({
      accessibility: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 6000
    });

    $('.explore-slider').slick({
      accessibility: false,
      dots: true,
      infinite: false,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

    // init logo slider
    $('.logo-slider:not(.redesign-logo-slider)').slick({
      accessibility: false,
      dots: false,
      infinite: true,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: false,
            arrows: true
          }
        }
      ]
    });

    // init mall redesign logo slider
    $('.redesign-logo-slider').slick({
      accessibility: true,
      dots: false,
      infinite: true,
      swipeToSlide: true,
      speed: 300,
      slidesToShow: 7,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false
        }
      }]
    });
    /* Fix an accessibility issue */
    $('.logo-slider').find('.slick-track').attr('aria-label', 'Featured stores');

    // init services slider
    $('.services-slider').slick({
      accessibility: false,
      dots: false,
      infinite: true,
      swipeToSlide: true,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3.25,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2.25,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false
          }
        }
      ]
    });

    // init shop online slider
    $('.shop-online-slider').slick({
      accessibility: true,
      dots: false,
      infinite: false,
      swipeToSlide: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.25,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false
          }
        }
      ]
    });

    $('.slick-arrow, .slick-dots button').on('click', function () {
      $('.hero-slider').slick('slickPause');
    });

    $('.hero-slider').on('swipe', function () {
      $('.hero-slider').slick('slickPause');
    });

    $('.hero-slider').on('lazyLoaded', function (slick, e, $img) {
      // find the picture tag that surrounds the lazyloaded img
      const $slidePicture = $img.closest('picture');
      const $fallbackImg = $img.closest('img');
      // get the next slide picture tag so you can do two at once
      // check this in out env - ex nodes in local test env
      const $nextSlidePicture = $slidePicture
        .parent()
        .next()
        .find('picture');

      // manually lazy loads picture tag source elements
      const loadImgs = function loadImgs(i, source) {
        const $source = $(source);

        // the img fallback is set to an empty gif, once you get the mobile src update to match
        // not sure its needed but might as well. mobile source tag doesnt have a media attr
        if(!$source.attr('media')) {
          $fallbackImg.attr('srcset', $source.data('lazy-srcset'));
          $fallbackImg.removeAttr('src');
        }

        // add proper srcset tag to load img
        $source.attr('srcset', $source.data('lazy-srcset'));
      };

      // Find <source> tags with data-lazy-srcset attribute
      $slidePicture.find('source[data-lazy-srcset]').each(loadImgs);

      // load the next one
      if($nextSlidePicture.length) {
        $nextSlidePicture.find('source[data-lazy-srcset]').each(loadImgs);
      }

      window.picturefill({ reevaluate: true });
    });

    $('.spg-ad-spot a, a.spg-ad-spot').mouseenter(function () {
      $(this).closest('.spg-ad-spot').find('.spg-ad-spot-image-container').addClass('cta-hover');
    }).mouseleave(function () {
      $(this).closest('.spg-ad-spot').find('.spg-ad-spot-image-container').removeClass('cta-hover');
    });
  }, false);

  if(document.location.href.indexOf('premiumoutlets') >= 0 && document.querySelector('.spo-carousel')) {
    spoCarousel.init();
  }
  if(/\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent)) {
    // IE / Edge video workaround for object-fit: cover;
    $('.main-hero').addClass('ms-video-workaround');
  }
  const htmlVideoControls = function htmlVideoControls() {
    const video = document.getElementById('video');
    const playToggle = document.querySelectorAll('.js-play');
    const pauseToggle = document.querySelector('.js-pause');
    const volumeToggle = document.querySelector('.js-volume');

    // pauses video
    const pauseVideo = function () {
      video.pause();
      playToggle[0].classList.add('paused');
      if(playToggle[1]) playToggle[1].classList.add('paused');
      pauseToggle.classList.remove('on');
      pauseToggle.classList.add('off');
    };

    // plays video
    const playVideo = function () {
      video.play();
      playToggle[0].classList.remove('paused');
      if(playToggle[1]) playToggle[1].classList.remove('paused');
      pauseToggle.classList.remove('off');
      pauseToggle.classList.add('on');
    };

    // event listener for the play button
    if(playToggle[0]) {
      playToggle[0].addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('paused')) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    }
    if(playToggle[1]) {
      playToggle[1].addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('paused')) {
          playVideo();
        } else {
          pauseVideo();
        }
      });
    }
    // event listener for the pause button
    if(pauseToggle) {
      pauseToggle.addEventListener('click', function (e) {
        e.stopPropagation();
        if(this.classList.contains('on')) {
          pauseVideo();
        }
      });
    }

    // Event listener for the mute button
    if(volumeToggle) {
      volumeToggle.addEventListener('click', function (e) {
        e.stopPropagation();
        if(video.muted) {
          // Unmute the video
          video.muted = false;
          // update the toggle
          volumeToggle.classList.remove('off');
          volumeToggle.classList.add('on');
        } else {
          // Mute the video
          video.muted = true;
          // update the toggle
          volumeToggle.classList.add('off');
          volumeToggle.classList.remove('on');
        }
      });
    }

    if(window.innerWidth <= 992) {
      if(video !== null) {
        video.addEventListener('loadeddata', () => {
          video.removeAttribute('autoplay');
          pauseVideo();
        });
      }
    }
  };

  htmlVideoControls();
})();

$(window).on('orientationchange resize', function () {
  $('.explore-slider').slick('resize');
});
